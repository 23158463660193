const COMPANY_ID = 1
const MAIN_BRANCH = 1
const COMPANY_NAME = "Soidao"
const PUSHER_KEY = "c94feae371875a73d0ed"
const API_URL = "https://soidao-intelsales.bisimosfa.com:8443 "
const FORM_URL = "http://soidao-intelsales.bisimosfa.com:8082"
export {
    API_URL,
    COMPANY_ID,
    MAIN_BRANCH,
    COMPANY_NAME,
    PUSHER_KEY,
    FORM_URL
}
